<!-- 新闻公告 -->
<template>
  <div class="page-notice">
    <div class="container">
      <div class="notice-side">
        <h2>新闻公告</h2>
        <ul>
          <li>
            <router-link
              class="news-item-children"
              :to="{ name: 'sciencenew' }"
            >
              科技新闻
            </router-link>
          </li>
          <li>
            <router-link
              class="news-item-children"
              :to="{ name: 'companynew' }"
            >
              公司新闻
            </router-link>
          </li>
        </ul>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    // this.$router.push({ name: 'sciencenew' })
  },
};
</script>

<style scoped>
.router-link-active {
  color: #059fff;
}
</style>
