<!-- 关于我们 -->
<template>
  <div>
    <Header />
    <Sidebar />
    <about />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import about from "./components/about.vue";
import company from "./components/company.vue";
import news from "./components/news.vue";
import contact from "./components/contact.vue";
export default {
  components: {
    Header,
    Footer,
    Sidebar,
    about,
    company,
    news,
    contact,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
@import "../../assets/static/template/Home/Zkeys/PC/Static/statics/css/front/about.css";
@import "../../assets/static/template/Home/Zkeys/PC/Static/css/common/module/about/ab_comm.css";
@import "../../assets/static/template/Home/Zkeys/PC/Static/css/common/common/common.css";
</style>
