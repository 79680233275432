<!-- 联系我们 -->
<template>
  <div class="about-outer about-contact clearfix auto">
    <div class="about-main-container">
      <div class="contact-info-outer">
        <ul class="clearfix">
          <li class="company-info-item company-info-tel">
            <div class="strong company-info-item-title single">
              服务热线： 0510-68860000
            </div>
            <div class="company-info-item-detail"></div>
          </li>
          <li class="company-info-item company-info-qq">
            <div class="strong company-info-item-title single">
              <a
                href="tencent://message/?uin=3461791957&Site=&Menu=yes"
                target="_blank"
                >在线QQ：3461791957</a
              >
            </div>
            <div class="company-info-item-detail"></div>
          </li>
          <li class="company-info-item company-info-email">
            <div class="strong company-info-item-title single">
              企业邮箱：online@panasia.com
            </div>
            <div class="company-info-item-detail"></div>
          </li>
          <li class="company-info-item company-info-postage">
            <div class="strong company-info-item-title single">
              邮政编码：214000
            </div>
          </li>

          <li class="company-info-item company-info-address">
            <div class="strong company-info-item-title">企业通讯地址</div>
            <div class="company-info-item-detail">
              无锡市梁溪区联东U谷33号泛亚大厦
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
@import "../../../assets/static/template/Home/Zkeys/PC/Static/css/module/about/contact.css";
</style>
