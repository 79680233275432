<!-- 头部组件 -->
<template>
  <div class="top-header">
    <div class="head-nav index-banner-bg headNav">
      <div class="container navCon">
        <div class="nav-left float-l">
          <h1 class="animated zoomIn">
            <router-link to="/"><a href="">
              <img
                  src="@/assets/Uploads/2022-04-29/626b9fd680a65.png"
                  width="122"
                  height="44"
                  alt="" /></a
            ></router-link>
          </h1>
          <ul class="nav-list float-l fl">
            <!-- <li class="nav_li" :class="{ active: ActiveClass.includes('act') }">
              <router-link to="/act"  class="nav">最新活动</router-link>
            </li> -->
            <li
              class="nav_li navCon-cate navCon_on"
              :class="{ active: ActiveClass.includes('eric') }"
              @mouseover="mouseOver"
              @mouseout="mouseOut"
            >
              <div class="navCon-cate-title nav" @mouseover="mouseOver" @mouseout="mouseOut"><b>产品</b></div>
              <div class="cateMenu"  v-show="cateMenuShow">
                <!--  -->
                <div class="list-menu" >
                  <ul>
                    <!-- offset-start index,length-max length -->
                    <li style="border-top: none" v-for="(item,index) in parent" :key="index" :class="{on:isActive === index}"  @mouseover="changeActive(index)" @mouseout="outActive(index)">
                      <div class="cate-tag">
                        <strong><a href="">{{item.categoryName}}</a></strong>
                        <div class="listModel">
                          <p>
                            <a v-for="(childrensitem,childrensindex) in item.childrens.slice(0,2)" :key="childrensindex" >{{childrensitem.categoryName}}</a>
                          </p>
                        </div>
                      </div>
                      <div class="list-item hide"  :class="{nohide: ishide === index}"  ref="hideref">
                        <div class="inner-ul">
                          <ul class="itemleft" v-for="(childrensitem,childrensindex) in item.childrens" :key="childrensindex">
                            <dl v-if="prodGroup[childrensitem.categoryId]">
<!--                             <router-link to="/prolist"  ><dt>{{childrensitem.categoryName}}</dt></router-link>-->
                              <dt @click="seemore(childrensitem)">{{childrensitem.categoryName}}</dt>
                            </dl>
                            <div v-show="prodGroup[childrensitem.categoryId]" class="news-list"  v-for="(prod,shopListindex) in prodGroup[childrensitem.categoryId]" :key="shopListindex">
                              <p>
                                <a
                                  :key='$route.fullPath'
                                  @click="change(prod.prodId)"
                                  class="text-over-flow"
                                  :categoryId='`${prod.categoryId}`'
                                  >{{prod.prodName}}
                                </a>
                                <div @click="seemore(childrensitem)" v-if="shopListindex == 14">
                                  <a>查看更多 ></a>
                                </div>
                              </p>
                            </div>

                            <div class="fn-clear"></div>

                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <!-- <li
              class="nav_li"
              :class="{ active: ActiveClass.includes('help') }"
            >
              <router-link to="/help"  class="nav">帮助中心</router-link>
            </li> -->
            <li
              class="nav_li po-re"
              :class="{ active: ActiveClass.includes('about') }"
              @mouseover="aboutOver" @mouseout="aboutOut"
            >
              <a class="nav" >关于我们</a>
              <div
                class="new-pop-list aboutus"
                v-show="aboutShow"
              >
                <div class="">
                  <div class="new-pop-link">
                    <router-link :to="{ name: 'company' }"><a href="">公司简介</a></router-link >
                    <router-link :to="{ name: 'sciencenew' }"><a href="">新闻公告</a></router-link>
                    <router-link :to="{ name: 'contact' }"><a href="">联系我们</a></router-link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="nav-right">
          <p class="top-user-info-1 hide">
            <!--<a href="/user/icp/index.html">备案</a>-->
            <a href="user/workorder/add.html" class="member">-</a>
            <a href="user/workorder/add.html">控制台</a>
            <a href="login.html">退出</a>
          </p>

          <p class="top-user-info-2" v-show="islogin">
           <router-link :to="{ name: 'personaldata',query:{username}}">
              <a href="" class="btn border-0 text-black">欢迎您:{{ username }}</a>
            </router-link>
            <a href="" class="btn btn-register border-r5" @click="exit">退出</a>
          </p>

          <p class="top-user-info-2" v-show="!islogin">
            <router-link :to="{ name: 'login' }">
              <a href="" class="btn border-0 text-black">登录</a>
            </router-link>
            <router-link :to="{ name: 'register' }">
              <a href="" class="btn btn-register border-r5">免费注册</a>
            </router-link>
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import { getShoplist, getAll ,getParent } from "@/api/all.js";
import { removeToken } from "@/utils/auth.js"
export default {
  data() {
    return {
      cateMenuShow: false,
      aboutShow: false,
      ActiveClass: "",
      username: "",
      parent: [],
      childrens: {},
      list: [],
      isActive: 0,
      ishide:0,
      shopList:[],
      // 商品 categoryId 做key
      prodGroup: {},
      islogin:'false',
      // sortField=sort
      // sortDirection=desc
    };
  },
  created() {
    const path = this.$route.path;
    this.ActiveClass = path;
    this.islogin = localStorage.getItem('islogin');
    this.username = localStorage.getItem('username');

    // 新方法
    getAll().then((res) => {
      this.parent = res.data;
      const newArr = {};
      this.parent = this.parent.reduce((init, item) => {
        const parent = item.parentId;
        const category = item.categoryId;
        if (parent == "0") {
          if (!newArr[category]) {
            newArr[category] = [];
          }
          item.childrens = newArr[category];
          init.push(item);
        } else {
          if (!newArr[parent]) {
            newArr[parent] = [];
          }
          newArr[parent].push(item);
        }
        return init;
      }, []);
      this.getShoplist();
    });

  },
  computed: {
    ...mapState(['user']),
  },
  methods: {

    seemore(item){
      console.log(item);
      this.$router.push({ name: 'prolist', query: {categoryId:item.parentId,parentId:item.categoryId}})
    },
    getShoplist(){
      getShoplist().then((res) => {
        res.data.rows.forEach(prod => {
          if (!this.prodGroup[prod.categoryId]) {
            this.prodGroup[prod.categoryId] = [];
          }
          if (this.prodGroup[prod.categoryId].length <= 14) {
            this.prodGroup[prod.categoryId].push(prod);
            this.prodGroup[prod.categoryId].sort((b, a) => a.sort - b.sort)
          }
        });
      })
    },
    change(id) {
      console.log(id)
      this.cateMenuShow = false;
      this.$router.push({ name: "buy", query: { id } });
    },
    exit() {
      removeToken('Admin-Token')
      localStorage.removeItem('islogin')
      localStorage.removeItem('username')
      localStorage.removeItem('phone')
      window.collectEvent('config', {
        user_unique_id: null,
      });
    },
    changeActive(index){
      this.isActive = index
      this.ishide = index
    },
    outActive(index){

    },
    //头部选中高亮
    mouseOver() {
      this.cateMenuShow = true;
    },
    // 移出
    mouseOut() {
      this.cateMenuShow = false;
    },
    aboutOver() {
      this.aboutShow = true;
    },
    // 移出
    aboutOut() {
      this.aboutShow = false;
    },
  }
};
</script>

<style>
/* .header .top-header {
  position: absolute;
} */
.head-nav .new-pop-list {
  background-color: #b60005;
}
.head-nav .new-pop-list a:hover {
  color: #141414;
  background-color: #d7d7d7;
}
.navCon-cate-title {
  cursor: pointer;
}
.p-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.nohide{
  display: block !important;
}
</style>
