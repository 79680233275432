<!-- 公司简介 -->
<template>
  <div>
    <div class="about-head">
      <div class="about-banner">
        <div class="header-info">
          <h1>关于我们</h1>
          <p>质量为本、客户为根、勇于拼搏、务实创新</p>
        </div>
      </div>
      <div class="about-cloumn">
        <ul class="container">
          <li class="about-nav nav1">
            <router-link active-class="active" to="/about/company"
              >公司简介</router-link
            >
          </li>
          <li class="about-nav nav1">
            <router-link active-class="active" to="/about/news"
              >新闻公告</router-link
            >
          </li>
          <li class="about-nav nav1">
            <router-link active-class="active" to="/about/contact"
              >联系我们</router-link
            >
          </li>
          <li class="about-nav nav1">
            <router-link active-class="active" to="/about/law"
              >法律声明</router-link
            >
          </li>
          <li class="about-nav nav1">
            <router-link active-class="active" to="/about/links"
              >友情链接</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
