<!-- 公司简介 -->
<template>
  <div>
    <div class="about-outer about-index clearfix auto">
      <div class="about-main-container">
        <div class="part-company-introduce border-bottom-1">
          <p class="ab-part-title">
            公司介绍
          </p>
          &emsp;&emsp;无锡市泛亚资讯网络有限公司成立于2003年4月，是一家从事软件外包和互联网信息业务流程外包服务的专业公司。多年的努力和拼搏，公司的客户遍布全国及东南亚地区，有承接超大订单的能力，是江苏地区专业的信息服务外包公司，现已成为为客户创造价值、深受用户广泛赞誉、具有卓越影响力的互联网服务机构，领跑在行业的前列，成为值得客户信赖的品牌。<br>
          &emsp;&emsp;泛亚凭借着丰富的产品体系和专业极致的服务，获得了客户的一致认可和好评。泛亚产品体系主要分为互联网基础服务、网络推广、电子商务、增值服务四大类别。互联网基础服务以域名、空间、网站建设及手机网站等产品为主，为客户提供企业互联网化的钥匙，携手企业进入互联网时代。泛亚网络推广服务，主要与Google、好搜、阿里巴巴等全球知名企业合作，为客户提供多样的网络展现，让网络营销更高效更便捷，带来更多商机。泛亚电子商务服务可以为客户提供B2B、B2C、直销、分销、DRP、CRM等多种电商解决方案，一站式系统化的为客户解决电商难题。增值服务包含400电话、企业QQ、SEO等。<br>
          &emsp;&emsp;泛亚作为互联网时代的弄潮儿，紧跟时代步伐，勇于突破，不断创新。在2014年发布了新的B2B 2.0产品--企翼网平台，同年发布了自主研发的微信第三方平台“微舟”产品。其中“企翼网”平台集B2B交易、网络推广、大数据等为一体，走在B2B2.0时代的前沿，为企业插上腾飞的翅膀。“微舟”产品是基于微信公众号的第三方营销平台，为企业提供基于微信平台的产品推广，微官网，互动营销插件，粉丝精细化管理，会员服务，微电商等。旨在帮助企业更好的运营微信公众号、抓住粉丝，提升品牌服务，占领移动端市场。<br>
          &emsp;&emsp;泛亚创建20多年，一直保持着高速发展。特别是近几年，在国际、国内经济形势非常不利的情况下，公司各项指标每年依然有喜人的涨幅。先后在福建、江西、上海、浙江、贵州、北京、香港和河北等地区成立了泛亚分子公司；还频频出手，先后投资了IDC、CMS、电子商务等领域的多家相关公司。2013年收购的商中在线科技股份有限公司正式登陆新三板；近期商中在线收购了北京新网互联软件服务有限公司、远近文化传播（上海）有限公司、无锡泛亚信息技术有限公司、无锡易联电商信息技术有限公司，这是商中在线乃至泛亚在发展过程中一个重要的里程牌。2014年5月泛亚正式投资收购了江苏汇洋信息科技股份有限公司和其旗下品牌“饭店网”（fandian.com）。同时泛亚也与国内外多家知名IT企业建立业务和研发合作关系，Google、360、微软、阿里巴巴、新浪等都成为了我们的合作伙伴。<br>
          &emsp;&emsp;公司质量管理方式是一切以用户为中心，互联网思维的核心思维就是用户思维，重点突出要以用户为中心，不仅是从品牌经营到企业经营要做到一切以用户为中心，而且，在企业价值链的各个环节也都要以用户为中心。全面质量管理的八项原则之一就是以顾客为关注焦点，这两个观点基本上是吻合的。<br>
          &emsp;&emsp;以客户为关注焦点，公司理解顾客当前和未来的需求，满足顾客要求并争取超越顾客期望。如果没有顾客，企业无法生存。以客户为关注焦点要求企业应该始终关注顾客，将理解和满足顾客的要求作为首要工作考虑，并以此安排所有的活动。顾客的要求是不断变化的，为了使顾客满意，以及创造竞争的优势，企业还应了解顾客未来的需求，并争取超越顾客的期望。以顾客为关注焦点虽然也是强调以客户为中心，但从某种程度上讲，是以企业角度为出发点的以客户为中心，由企业提供满足顾客要求和期望的产品。<br>
          &emsp;&emsp;注重用户参与感和用户体验至上，用户思维除了一切以用户为中心，还强调注重用户参与感和用户体验至上。互联网的存在，使得消费者掌握了更多的产品、价格、品牌方面的信息，使得市场竞争更为充分、公开、透明，市场由企业主导转变为消费者主导，消费者主权时代真正到来。可以说，互联网思维下的一切以用户为中心是以客户角度为出发点的，由客户提出需要什么样的产品，企业根据客户的需求进行个性化定制并参与体验。<br>
          &emsp;&emsp;在推动战略性新兴产业健康发展过程中，中国互联网行业必将有个爆发式的发展，泛亚将抓住这个发展机遇，与时俱进，不断开拓创新，在促进中小企业信息化发展的过程中进一步做大做强，为李克强总理提出的“互联网+”全新理念，添砖加瓦，贡献全部力量。<br>
          &emsp;&emsp;泛亚作为互联网信息服务全国百强企业，在推动战略性新兴产业健康发展的过程中，将进一步激发全体员工的创新活力和工作热情，奋发图强，使泛亚更上一层楼，为本地区乃至全国及东南亚地区的经济建设作出新的贡献。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.part-company-introduce {
  font-family: 微软雅黑;
  line-height: 32px;
  background-color: rgb(255, 255, 255);
  color: rgb(102, 102, 102);
  font-size: 16px;
}
</style>
